<template>
  <div>
    <plants-list-add-new
      :is-add-new-plant-sidebar-active.sync="isAddNewPlantSidebarActive"
      @refetch-data="refetchData"
    />
    <plants-list-edit
      :key="JSON.stringify(selectedPlant)"
      :is-edit-plant-sidebar-active.sync="isEditPlantSidebarActive"
      :plant.sync="selectedPlant"
      @refetch-data="refetchData"
    />
    <plant-milking-times
      :key="JSON.stringify(selectedPlant) + 'milkingtimes'"
      :is-plant-milking-times-sidebar-active.sync="
        isPlantMilkingTimesSidebarActive
      "
      :plant.sync="selectedPlant"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="
                  isAddNewPlantSidebarActive = !isAddNewPlantSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("newPlant") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPlantListTable"
        class="position-relative"
        :items="fetchPlants"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(pictureurl)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  'https://api.herdoncloud.com/Plant_Pictures/' +
                  data.item.pictureurl
                "
                :text="avatarText(data.item.plantname)"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.plantname }}
            </span>
            <small class="text-muted">@{{ data.item.responsible }}</small>
          </b-media>
        </template>
        <template #cell(milkingTimes)="data">
          <b-button
            variant="outline-primary"
            @click="() => openMilkingTimes(data.item)"
          >
            <div class="d-flex">
              <feather-icon class="text-primary" icon="ClockIcon" size="25" />
              <small class="text-dark">{{ $t("milkingTimes") }}</small>
            </div>
          </b-button>
        </template>

        <template #cell(select)="data">
          <b-button
            size="sm"
            :disabled="$store.state.app.selectedPlantId == data.item.id"
            :variant="
              $store.state.app.selectedPlantId == data.item.id
                ? 'success'
                : 'primary'
            "
            @click="() => selectPlant(data.item)"
          >
            {{
              $store.state.app.selectedPlantId == data.item.id
                ? $t("selected")
                : $t("select")
            }}
          </b-button>
        </template>

        <template #cell(delete)="data">
          <b-button
            variant="outline-danger"
            :disabled="
              $store.state.app.selectedPlantId == data.item.id ||
              totalPlants == 1
            "
            @click="deletePlant(data.item.id)"
          >
            <div class="d-flex">
              <feather-icon class="text-danger" icon="Trash2Icon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-button>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPlants"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BMedia,
  BAvatar,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usePlantList from "./usePlantList";
import PlantsListAddNew from "./PlantsListAddNew.vue";
import PlantsListEdit from "./PlantsListEdit.vue";
import PlantMilkingTimes from "./PlantMilkings/PlantMilkingTimes.vue";
import store from "@/store";
export default {
  components: {
    PlantsListAddNew,
    PlantsListEdit,
    PlantMilkingTimes,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BMedia,
    BAvatar,
  },
  data() {
    return {};
  },

  setup({ emit, vm }) {
    const isAddNewPlantSidebarActive = ref(false);
    const isEditPlantSidebarActive = ref(false);
    const isPlantMilkingTimesSidebarActive = ref(false);
    const selectedPlant = ref({});
    const openEditSideBar = (item) => {
      selectedPlant.value = item;
      isEditPlantSidebarActive.value = !isEditPlantSidebarActive.value;
    };
    const openMilkingTimes = (item) => {
      selectedPlant.value = item;
      isPlantMilkingTimesSidebarActive.value =
        !isPlantMilkingTimesSidebarActive.value;
    };
    const selectPlant = (plant) => {
      store.commit("app/UPDATE_PLANT", plant.id);
      store.commit("app/UPDATE_PLANT_TYPE", plant);
      localStorage.setItem("plantid", plant.id);
    };

    const {
      fetchPlants,
      tableColumns,
      perPage,
      currentPage,
      totalPlants,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlantListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deletePlant,
    } = usePlantList();

    return {
      // Sidebar
      isAddNewPlantSidebarActive,
      isEditPlantSidebarActive,
      isPlantMilkingTimesSidebarActive,
      openMilkingTimes,
      openEditSideBar,
      selectedPlant,

      fetchPlants,
      tableColumns,
      perPage,
      currentPage,
      totalPlants,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlantListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deletePlant,
      selectPlant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
