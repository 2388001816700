import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function usePlantList() {
  // Use toast
  const toast = useToast();

  const refPlantListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "select", label: VueI18n.t("select"), sortable: false },

    { key: "pictureurl", label: VueI18n.t("plantName") },
    { key: "address", label: VueI18n.t("address"), sortable: false },
    { key: "milkingTimes", label: VueI18n.t("milkingTimes"), sortable: false },
    {
      key: "responsiblemobile",
      label: VueI18n.t("resonsibleMobile"),
      sortable: false,
    },
    { key: "email", label: VueI18n.t("email"), sortable: false },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalPlant = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refPlantListTable.value
      ? refPlantListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlant.value,
    };
  });

  const refetchData = () => {
    refPlantListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchPlants = async (ctx, callback) => {
    var plant = await store
      .dispatch("plantsModule/fetchPlants")

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("plants") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (searchQuery.value != "")
      plant = plant.filter((x) =>
        x.plantname
          .toLowerCase()
          .startsWith(searchQuery.value.toLocaleLowerCase())
      );
    totalPlant.value = plant.length;
    return plant;
  };
  const deletePlant = (ctx) => {
    store
      .dispatch("plantsModule/deletePlant", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("plant") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("plant") }),
          },
        });
      });
  };

  const addRandomConditionAndWeight = (animalid) => {
    // condition should be between 0 and 5
    var condition = Math.floor(Math.random() * 5) + 1;
    var startWeight = 0;
    var increaseWeight = Math.floor(Math.random() * 150) + 40;
    // 8 months ago
    var startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 8);

    for (var i = 0; i < 8; i++) {
      fetch("https://api.herdoncloud.com/Animals/AddAnimalWeightandCondition", {
        headers: {
          accept: "application/json, text/plain, */*",
          "accept-language": "tr-TR,tr;q=0.9,en-US;q=0.8,en;q=0.7,id;q=0.6",
          authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjM3IiwidXNlcmlkIjoiMzciLCJmYW1pbHlfbmFtZSI6IjU0IiwibmJmIjoxNjc4MzU0NzEwLCJleHAiOjE2NzgzOTc5MTAsImlhdCI6MTY3ODM1NDcxMH0.zbE5InVN14mSv1KHNE06woJ2E091llOtAv0Ey8Ukb5U",
          "content-type": "application/json",
          culinfo: "tr",
          culture: "tr",
          plantid: "103",
          "sec-ch-ua":
            '"Chromium";v="110", "Not A(Brand";v="24", "Google Chrome";v="110"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"macOS"',
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "cross-site",
        },
        referrer: "http://localhost:8080/",
        referrerPolicy: "strict-origin-when-cross-origin",
        // "body":
        // "{\"id\":0,\"companyid\":\"54\",\"plantid\":\"103\",\"animalid\":24,\"recordeddate\":\"2023-01-10\",\"condition\":\"4\",\"weight\":\"55\"}",
        body: JSON.stringify({
          id: 0,
          companyid: "54",
          plantid: "103",
          animalid: animalid,
          recordeddate: startDate.toISOString().split("T")[0],
          condition: condition,
          weight: startWeight + increaseWeight,
        }),
        method: "POST",
        mode: "cors",
        credentials: "include",
      });
      startDate.setMonth(startDate.getMonth() + 1);
      startWeight += increaseWeight;
      condition = Math.floor(Math.random() * 5) + 1;
      increaseWeight = Math.floor(Math.random() * 50) + 1;
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPlants,
    tableColumns,
    perPage,
    currentPage,
    totalPlant,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlantListTable,
    deletePlant,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
