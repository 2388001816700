<template>
  <div>
    <b-modal
      id="add-new-milking-sidebar"
      centered
      :visible="isAddNewMilkingSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('milkingAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankMilking)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-milking-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <validation-provider
            #default="validationContext"
            name="timename"
            rules="required"
          >
            <b-form-group :label="$t('timeName')" label-for="timename">
              <b-form-input
                v-model="blankMilking.timename"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- timemin -->
          <validation-provider
            #default="validationContext"
            name="timemin"
            rules="required"
          >
            <b-form-group :label="$t('timeMin')" label-for="timemin">
              <b-form-timepicker
                v-model="blankMilking.timemin"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- timemax -->
          <validation-provider
            #default="validationContext"
            name="timemax"
            rules="required"
          >
            <b-form-group :label="$t('timeMax')" label-for="timemax">
              <b-form-timepicker
                v-model="blankMilking.timemax"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTimepicker,
  BFormInput,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormTimepicker,
    BFormInvalidFeedback,
    BFormInput,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMilkingSidebarActive",
    event: "update:is-add-new-milking-sidebar-active",
  },
  props: {
    isAddNewMilkingSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedPlant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      axios: this.$http,
    };
  },
  watch: {
    blankMilking: {
      handler: function (val, oldVal) {
        // if timemin after timemax then swap
        if (val.timemin > val.timemax) {
          let temp = val.timemin;
          val.timemin = val.timemax;
          val.timemax = temp;
        }
      },
      deep: true,
    },
  },

  setup(props, { emit, http }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const milkinges = ref([]);

    const blankMilkingData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      timemin: "00:00:00",
      timemax: "00:00:00",
      timename: "",
    };

    const blankMilking = ref(JSON.parse(JSON.stringify(blankMilkingData)));
    const resetblankMilking = () => {
      blankMilking.value = JSON.parse(JSON.stringify(blankMilkingData));
    };

    const onSubmit = (milking) => {
      store
        .dispatch("plantsModule/addPlantMilkingTimes", {
          milkingTimes: milking,
        })
        .then((res) => {
          emit("refetch-data");
          emit("update:is-add-new-milking-sidebar-active", false);
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankMilking);
    return {
      blankMilking,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      milkinges,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
