<template>
  <div>
    <b-modal
      id="eedit-milking-sidebar"
      centered
      :visible="isEditMilkingSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalMilking')"
      @ok="onSubmit(blankMilkingData)"
      @hidden="formValidation(resetblankmilking).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm modal-form"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankmilking).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <validation-provider
            #default="validationContext"
            name="timename"
            rules="required"
          >
            <b-form-group :label="$t('timeName')" label-for="timename">
              <b-form-input
                v-model="blankMilkingData.timename"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- timemin -->
          <validation-provider
            #default="validationContext"
            name="timemin"
            rules="required"
          >
            <b-form-group :label="$t('timeMin')" label-for="timemin">
              <b-form-timepicker
                v-model="blankMilkingData.timemin"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- timemax -->
          <validation-provider
            #default="validationContext"
            name="timemax"
            rules="required"
          >
            <b-form-group :label="$t('timeMax')" label-for="timemax">
              <b-form-timepicker
                v-model="blankMilkingData.timemax"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTimepicker,
  BFormInput,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTimepicker,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMilkingSidebarActive",
    event: "update:is-edit-milking-sidebar-active",
  },
  props: {
    isEditMilkingSidebarActive: {
      type: Boolean,
      required: true,
    },
    milking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankMilkingData: { ...this.milking },
      formValidation: formValidation,
      milkings: [],
    };
  },
  watch: {
    blankMilkingData: {
      handler: function (val, oldVal) {
        // if timemin after timemax then swap
        if (val.timemin > val.timemax) {
          let temp = val.timemin;
          val.timemin = val.timemax;
          val.timemax = temp;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  async created() {},
  methods: {
    async changed(val) {
      this.resetblankmilking();
      this.$emit("update:is-edit-milking-sidebar-active", val);
    },
    onSubmit(milking) {
      store
        .dispatch("plantsModule/updatePlantMilkingTimes", {
          milkingTimes: milking,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-milking-sidebar-active", false);
        });
    },
    async resetblankmilking() {
      this.blankMilkingData = {
        ...this.milking,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
