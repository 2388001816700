<template>
  <b-modal
    id="plant-milking-times"
    centered
    :visible="isPlantMilkingTimesSidebarActive"
    bg-variant="white"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="
      $t('plantWithName', { plantName: plant.plantname }) +
      ' ' +
      $t('milkingTimes')
    "
    @hidden="formValidation(resetblankplant).resetForm"
    @change="(val) => changed(val)"
  >
    <plant-milking-add
      :is-add-new-milking-sidebar-active.sync="isAddNewMilkingSidebarActive"
      :selected-plant="plant"
      @refetch-data="resetblankplant"
    ></plant-milking-add>
    <plant-milking-edit
      :key="JSON.stringify(selectedMilking)"
      :is-edit-milking-sidebar-active.sync="isEditMilkingSidebarActive"
      :milking="selectedMilking"
      @refetch-data="resetblankplant"
    ></plant-milking-edit>

    <div class="mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col cols="12" md="12">
          <div
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <span></span>
            <b-button
              :disabled="blankMilkingData.length === 8"
              variant="primary"
              @click="
                isAddNewMilkingSidebarActive = !isAddNewMilkingSidebarActive
              "
            >
              <span class="text-nowrap">{{ $t("newMilking") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table responsive :items="blankMilkingData" :fields="tableColumns">
      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
            class="p-1"
            variant="outline-primary"
            @click="
              isEditMilkingSidebarActive = !isEditMilkingSidebarActive;
              selectedMilking = data.item;
            "
          >
            <feather-icon icon="EditIcon" size="16"></feather-icon>
          </b-button>

          <b-button
            variant="outline-danger"
            class="text-danger p-1 ml-1"
            @click="deleteMilking(data.item)"
          >
            <feather-icon icon="TrashIcon" size="16"></feather-icon>
          </b-button>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { BModal, BTable, BRow, BCol, BButton } from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import PlantMilkingAdd from "./PlantMilkingAdd.vue";
import PlantMilkingEdit from "./PlantMilkingEdit.vue";

export default {
  components: {
    BModal,
    BTable,
    BRow,
    BCol,
    BButton,
    PlantMilkingAdd,
    PlantMilkingEdit,
  },

  model: {
    prop: "isPlantMilkingTimesSidebarActive",
    event: "update:is-plant-milking-times-sidebar-active",
  },
  props: {
    isPlantMilkingTimesSidebarActive: {
      type: Boolean,
      required: true,
    },
    plant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankMilkingData: [],
      selectedMilking: {},
      tableColumns: [
        {
          key: "timename",
          label: this.$t("timeName"),
          sortable: true,
        },
        {
          key: "timemin",
          label: this.$t("timeMin"),
          sortable: true,
        },
        {
          key: "timemax",
          label: this.$t("timeMax"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      isAddNewMilkingSidebarActive: false,
      isEditMilkingSidebarActive: false,
      formValidation: formValidation,
    };
  },
  mounted() {
    this.resetblankplant();
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblankplant();
      this.$emit("update:is-plant-milking-times-sidebar-active", val);
    },
    async deleteMilking(milking) {
      await this.$store.dispatch("plantsModule/deletePlantMilkingTimes", {
        milkingTimes: milking,
      });
      this.resetblankplant();
    },
    async resetblankplant() {
      this.blankMilkingData = await this.$store.dispatch(
        "plantsModule/fetchPlantMilkingTimes",
        this.plant.id
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-plant-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
